import { graphql, Link } from "gatsby";
import * as React from "react";
import { Container } from "reactstrap";
import ContentSection from "../components/contentSection";
import Layout from "../components/layout";
import SEO from "../components/seo";

interface PressReleaseProps {
    data: {
        markdownRemark: {
            frontmatter: {
                title: string;
                from: string;
                contact: string;
                release_date: string;
            };
            html: string;
        };
    };
}

const PressRelease: React.FunctionComponent<PressReleaseProps> = ({
    data: {
        markdownRemark: {
            frontmatter: { title, from, contact, release_date },
            html
        }
    }
}) => (
    <Layout>
        <SEO title={title} />

        <ContentSection title={title}>
            <p>{release_date}</p>

            <div
                dangerouslySetInnerHTML={{ __html: html }}
                className="mt-3 mb-4"
            />

            <p>MEDIA CONTACT: {contact}</p>

            <Link to="/news">&larr; Back to News</Link>
        </ContentSection>
    </Layout>
);

export default PressRelease;

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title
                from
                contact
                release_date(formatString: "MMMM D, YYYY")
            }
            html
        }
    }
`;
